import parse, { DomElement, domToReact } from "html-react-parser";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

interface ContentParserProps {
  content: string;
}

interface PostCodeProps {
  language: string | undefined;
  children: any;
}

export const PostCode: React.FC<PostCodeProps> = ({ language, children }) => {
  const props = {
    tabIndex: "0",
  };

  return (
    <SyntaxHighlighter
      style={tomorrow}
      language={language}
      customStyle={{
        borderRadius: ".5rem",
        marginBottom: "1.5rem",
      }}
      {...props}
    >
      {children}
    </SyntaxHighlighter>
  );
};

const getLanguage = (node: DomElement) => {
  if (node.attribs && node.attribs.class != null) {
    return node.attribs.class;
  }
  return undefined;
};

const getCode = (node: any) => {
  if (
    node.children &&
    node.children.length > 0 &&
    node.children[0].name === "code"
  ) {
    return node.children[0].children;
  }

  return node.children;
};

const replaceCode = (node: DomElement) => {
  if (node.name === "pre") {
    return (
      node.children &&
      node.children.length > 0 && (
        <PostCode language={getLanguage(node)}>
          {domToReact(getCode(node))}
        </PostCode>
      )
    );
  }
};

const ContentParser: React.FC<ContentParserProps> = ({ content }) => {
  return <div>{parse(content, { replace: replaceCode })}</div>;
};

export default ContentParser;
