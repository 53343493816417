import { graphql, Link } from "gatsby";
import { FluidObject } from "gatsby-image";
import Img from "gatsby-image/withIEPolyfill";
import React from "react";
import ContentParser from "../components/contentParser";
import Layout from "../components/layout";
import SEO from "../components/seo";

export interface PostInterface {
  id: number;
  title: string;
  uri: string;
  slug: string;
  date: Date;
  content: string;
  author: {
    name: string;
    firstName?: string;
    lastName?: string;
  };
  categories?: {
    nodes?: [
      {
        name: string;
        slug: string;
      }
    ];
  };
  featuredImage?: {
    imageFile: {
      altText: string;
      childImageSharp: {
        fluid: FluidObject;
      };
    };
  };
  seo: {
    canonical: string;
    title: string;
    metaDesc: string;
  };
}

interface PostProps {
  location: Location;
  data: {
    wordPress: {
      post: PostInterface;
    };
  };
}

const Post: React.FC<PostProps> = ({ location, data }) => {
  const {
    wordPress: { post },
  } = data;

  return (
    <Layout className="bg-gray pt-12">
      <SEO
        title={post.seo.title}
        description={post.seo.metaDesc}
        canonical={post.seo.canonical}
      />
      <div className="w-11/12 lg:w-3/5 mx-auto py-8 px-5 md:px-10 bg-white shadow-lg rounded-md">
        <h1 className="pb-2">{post.title}</h1>
        <div className="flex flex-col md:flex-row pb-6">
          <p>
            By:{" "}
            <span className="text-scarlet">
              {post.author.firstName
                ? post.author.firstName + " " + post.author.lastName
                : post.author.name}
            </span>
          </p>
          {post.categories && (
            <div className="md:pl-3 flex items-center">
              <span className="mr-1">Category: </span>
              <ul className="flex">
                {post?.categories?.nodes?.map((category, index) => (
                  <li key={category.name}>
                    {index !== 0 && <span className="ml-1">| </span>}
                    <Link
                      to={`/blog/category/${category.slug}`}
                      className="text-scarlet font-normal hover:text-darkgray"
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        {post.featuredImage && (
          <div className="pb-6">
            <Img
              alt={post.featuredImage?.imageFile.altText}
              fluid={post.featuredImage?.imageFile.childImageSharp.fluid}
            />
          </div>
        )}
        <ContentParser content={post.content} />
      </div>
    </Layout>
  );
};

export default Post;

export const pageQuery = graphql`
  query GET_POST($id: ID!) {
    wordPress {
      post(id: $id) {
        title
        content
        uri
        author {
          name
          firstName
          lastName
        }
        featuredImage {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        categories {
          nodes {
            name
            slug
          }
        }
        seo {
          canonical
          title
          metaDesc
        }
      }
    }
  }
`;
